import React, {useContext} from 'react';
import {Link} from 'gatsby';
import clsx from 'clsx';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import classes from './pagination-btns.module.css';

interface PaginationBtnsProps {
  numPages: number;
  currentPage: number;
}

const PaginationBtns: React.FC<PaginationBtnsProps> = ({numPages, currentPage}) => {
  const {theme} = useContext(GlobalStateContext);
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();
  const lastPage = numPages.toString();

  return (
    <div className={classes.pagination_btns}>
      {currentPage > 2 && (
        <Link to="../" className={classes.page_num_btn}>
          1
        </Link>
      )}
      {currentPage == 4 && (
        <Link to="../2" className={classes.page_num_btn}>
          2
        </Link>
      )}
      {currentPage > 4 && <div className={classes.page_num_btn}>...</div>}
      {!isFirst && (
        <Link to={`../${prevPage}`} className={classes.page_num_btn}>
          {currentPage == 2 ? 1 : prevPage}
        </Link>
      )}
      <div
        className={clsx(
          classes.page_num_btn,
          classes.current_page,
          theme !== 'regular' && classes.current_black_and_white,
        )}
      >
        {currentPage}
      </div>
      {currentPage < numPages - 1 && (
        <Link to={isFirst ? nextPage : `../${nextPage}`} className={classes.page_num_btn}>
          {nextPage}
        </Link>
      )}
      {currentPage < numPages - 3 && <div className={classes.page_num_btn}>...</div>}
      {currentPage == numPages - 3 && (
        <Link to={`../${String(numPages - 2)}`} className={classes.page_num_btn}>
          {String(numPages - 1)}
        </Link>
      )}
      {!isLast && (
        <Link to={isFirst ? lastPage : `../${lastPage}`} className={classes.page_num_btn}>
          {lastPage}
        </Link>
      )}
    </div>
  );
};

export default PaginationBtns;
