import React, {useContext} from 'react';
import {graphql} from 'gatsby';
import {IGatsbyImageData} from 'gatsby-plugin-image';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/container';
import PageTitle from '../components/page-title';
import NewsGridItem from '../components/news-grid-item';
import NewsListItem from '../components/news-list-item';
import PaginationBtns from '../components/pagination-btns';
import classes from './news-template.module.css';

type NewsQueryResult = {
  allNewsYaml: {
    edges: {
      node: {
        id: string;
        title: string;
        image: IGatsbyImageData;
        date: string;
      };
    }[];
  };
};

export const query = graphql`
  query newsListQuery($skip: Int!, $limit: Int!) {
    allNewsYaml(sort: {fields: date, order: DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
          id
          title
          image {
            childImageSharp {
              gatsbyImageData(width: 815, quality: 100, aspectRatio: 1.8, placeholder: BLURRED)
            }
          }
          date
        }
      }
    }
  }
`;

interface NewsProps {
  data: NewsQueryResult;
  pageContext: {
    limit: number;
    skip: number;
    numPages: number;
    currentPage: number;
  };
}

const News: React.FC<NewsProps> = ({data, pageContext}) => {
  const {numPages, currentPage} = pageContext;
  const {theme} = useContext(GlobalStateContext);
  const news = data.allNewsYaml.edges;
  
    return (
    <Layout>
      <SEO title="Новини" />
      <section>
        <Container>
          <PageTitle title="Новини" />
        </Container>

        {theme == 'regular' ? (
          <div className={classes.news_grid}>
            {news.map(({node}) => (
              <NewsGridItem key={node.id} id={node.id} title={node.title} image={node.image} />
            ))}
          </div>
        ) : (
          <Container className={classes.news_list}>
            {news.map(({node}) => (
              <NewsListItem key={node.id} id={node.id} title={node.title} date={node.date} />
            ))}
          </Container>
        )}

        <PaginationBtns numPages={numPages} currentPage={currentPage} />
      </section>
    </Layout>
  );
};

export default News;
